import styled from "styled-components/macro";
import { HomepageBannerPhoto, HomepageCover } from "../../../assets/images";
import { CenteredDivOnBothAxis, mediaQueries } from "../../../utils";
export const HomePageBannerContainer = styled("div")`
	position: relative;
	height: calc(100svh - 11rem);
	min-height: calc(100vh - 11rem);
	width: 100%;
`;

export const ImageContainer = styled("div")`
	position: absolute;
	height: 100%;
	width: 100%;
	/* background: url(${HomepageBannerPhoto}); */
	background: url(${HomepageCover});
	background-repeat: no-repeat;
	background-position: bottom -300px left 0;
	background-attachment: scroll;
	background-size: cover;
	z-index: -2;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.6);
	}

	@media ${mediaQueries.desktopL} {
		background-position: bottom 0 left 0;
	}
`;

export const TextContainer = styled(CenteredDivOnBothAxis)`
	position: relative;
	flex-direction: column;
	padding-top: 25rem;
	font-family: ${(props) => props.theme.font.fuzzyBubbles};
	z-index: -1;
`;

export const Title = styled("p")`
	color: ${(props) => props.theme.colors.darkYellow};
	font-size: ${(props) => props.theme.fontSizes.extraLarge};
	letter-spacing: 5px;
	@media ${mediaQueries.tablet} {
		font-size: ${(props) => props.theme.fontSizes.xlarge};
		letter-spacing: 3px;
	}
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.large};
		letter-spacing: 1px;
	}
`;
export const Description = styled("p")`
	color: ${(props) => props.theme.colors.white};
	font-size: ${(props) => props.theme.fontSizes.xlarge};
	letter-spacing: -1px;
	text-align: center;
	padding: 0 0.5rem;
	@media ${mediaQueries.tablet} {
		font-size: ${(props) => props.theme.fontSizes.large};
	}
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;

export const SecondDescription = styled("p")`
	color: ${(props) => props.theme.colors.darkYellow};
	font-size: ${(props) => props.theme.fontSizes.large};
	letter-spacing: -1px;
	@media ${mediaQueries.tablet} {
		font-size: ${(props) => props.theme.fontSizes.xmedium};
	}
	@media ${mediaQueries.mobileL} {
		font-size: ${(props) => props.theme.fontSizes.medium};
	}
`;
