import React, { FC, useEffect } from "react";
import * as S from "./Home.styles";
import { HelmetContainer, HomePageBanner } from "../../molecules";
import { ContactSection, JobsSection, PhotoTextSection } from "../../organisms";
import {
	CurteBloc,
	LocJoacaPhoto,
	MotoScarificator,
	TractorPhoto,
} from "../../../assets/images";
import { homepageTexts } from "../../../mocks";

export const Home: FC = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<HelmetContainer
				pageTitle="RDA | Întreținere și amenajare spații verzi - Brașov"
				pageDescription="Amenajări grădini și spații verzi Brașov | RDA GreenLand îți propune soluții complete pentru amenajari spatii verzi Brasov, sau pentru întreținerea spatiilor verzi! Nu ezitați a ne contacta."
			/>
			<S.HomeContainer>
				<HomePageBanner />
				<PhotoTextSection
					reverseSection={false}
					title={homepageTexts.firstSection.title}
					description={homepageTexts.firstSection.description}
					images={[
						{ image: `${TractorPhoto}`, isLandscape: false },
						{ image: `${LocJoacaPhoto}`, isLandscape: true },
					]}
					hasNumbers={false}
				/>
				<JobsSection />
				<PhotoTextSection
					hasNumbers={true}
					reverseSection={true}
					isCentered={true}
					title={homepageTexts.secondSection.title}
					list={homepageTexts.secondSection.list}
					images={[
						{ image: `${MotoScarificator}`, isLandscape: false },
						{ image: `${CurteBloc}`, isLandscape: true },
					]}
				/>
				<ContactSection />
			</S.HomeContainer>
		</>
	);
};
