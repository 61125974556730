import React from "react";
import * as S from "./HomePageBanner.styles";
import { homepageTexts } from "../../../mocks";
export const HomePageBanner = () => {
	return (
		<S.HomePageBannerContainer>
			<S.ImageContainer></S.ImageContainer>
			<S.TextContainer>
				<S.Title>{homepageTexts.homepageTitle}</S.Title>
				<S.Description>{homepageTexts.homepageDescription}</S.Description>
				<S.SecondDescription>
					{homepageTexts.homepageExtraDescription}
				</S.SecondDescription>
			</S.TextContainer>
		</S.HomePageBannerContainer>
	);
};
